<template>
  <div class="content">

    <div class="infoWrap">
      <div class="infoSpecific">
        <p class="infoTitle">申请人</p>
        <input v-model="form.name" class="infoInput" placeholder="请输入申请人姓名" type="text">
      </div>
      <div class="infoSpecific">
        <p class="infoTitle">身份证</p>
        <input v-model="form.card" class="infoInput" placeholder="请输入身份证" type="number">
      </div>
      <div class="infoSpecific">
        <p class="infoTitle">手机号</p>
        <input v-model="form.mobile" class="infoInput" placeholder="请输入手机号" type="number">
      </div>
    </div>
    <div class="serviceWrap">
      <van-checkbox v-model="checked" class="checkbox" icon-size="12px">
        本人已充分理解并同意
        <span class="serviceContent" @click="lookServeice">《用户服务协议》</span>
      </van-checkbox>
    </div>
    <div class="lookLines">
      <div class="lookLinesBtn" @click="lookLines">查看额度领惊喜</div>
    </div>

    <!--下载app二维码-->
    <div v-if="showimg" class="mask" @click="closeshow">
      <div class="picture-box">
        <img class="picture" src="../../../assets/images/wxtwo.jpg">
        <div class="btn">长按图片保存到手机相册</div>
        <!-- <div class="btn" style="background: #ff7800">预览海报长按分享</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  Checkbox,
  CheckboxGroup,
  Toast
} from 'vant'

Vue.use(Toast)
  .use(Checkbox)
  .use(CheckboxGroup)
import { goLookLoans, goLookLoanLink, isLookLoan } from '@/services/comeOn.js'
import Utils from '@/utils/aes.js'
import { wxConfigInit } from '@/utils/wx-api'
// import { sharePage } from '@/services/activity'

export default {
  name: 'LoanIndex',
  data() {
    return {
      form: {
        name: '', // 申请人
        card: '', // 身份证
        mobile: ''// 手机号
      },
      checked: true, // 服务协议
      showimg: false
    }
  },

  created() {

  },
  mounted() {
    this.shareInfo()// 分享
    this.isInfo()// 判断用户是否录入信息
  },
  methods: {
    closeshow() {
      this.showimg = false
    },
    getshowimg(item) {
      this.showimg = item
    },
    isInfo() {
      const _this = this
      if (_this.$store.state.isSkipLinks) {
        isLookLoan({
          mid: window.localStorage.getItem('uid')
        }).then(res => {
          if (Number(res.code) === 200) {
            if (res.data === false) {
              _this.$store.commit('changeIsSkipLinks', false)
              _this.threeLink()
            }
          } else {
            Toast(res.msg)
          }
        })
      } else if (_this.$store.state.isSkipLinks === false) {
        return false
      }
    },
    // 3方跳转链接
    threeLink() {
      goLookLoanLink().then(res => {
        if (Number(res.code) === 200) {
          window.location.href = res.data.link
        } else {
          Toast(res.msg)
        }
      })
    },
    lookServeice() {
      this.$router.push({
        path: '/serviceIndex'
      })
    },
    lookLines() {
      const _this = this
      if (this.form.name === '') {
        Toast('请填写姓名')
        return
      } else if (this.form.card === '') {
        Toast('请填写身份证')
        return
      } else if (this.form.mobile === '') {
        Toast('请填写手机号')
        return
      } else if (this.checked === false) {
        Toast('请选择用户服务协议')
        return
      }
      const goLookLoansData = {
        mid: window.localStorage.getItem('uid'),
        name: _this.form.name,
        encryptedData: Utils.encrypt(JSON.stringify({
          'card': _this.form.card,
          'mobile': _this.form.mobile
        }), 'SUdkXD0wzd1LnwPd', Math.floor(Math.random() * 9999999999999999)),
        iv: Math.floor(Math.random() * 999999999999999999)// 偏移量（18位随机字符串）
      }
      goLookLoans(goLookLoansData).then(res => {
        if (Number(res.code) === 200) {
          _this.threeLink()
        } else {
          Toast(res.msg)
        }
      })
    },
    shareInfo() {
      const shareInfo = {
        title: '贷款额度最高20万元，极速审批，快速到账，安全贷款，超低利率......', // 分享标题
        desc: '我要贷款>', // 分享描述
        link: 'https://web.yuetao.vip/loanIndex?codeNumber=' + window.localStorage.getItem('recode'), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-18/11/yuelvhuicj6IVTzSP81605671407.png' // 分享图标
      }
      wxConfigInit(shareInfo)
      // this.$store.dispatch('wxConfigInit', shareInfo)
    }
  }
}
</script>

<style lang="less" scoped>
html, body, #app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
}

.content {
  width: 100%;
  height: 100%;
  position: fixed;
  background-image: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-06-24/15/yuelvhui7darZvh74O1624519159.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.infoWrap {
  width: 95%;
  height: auto;
  margin: 229px auto 14px auto;
  background-color: #fff;
  padding: 32px 0 27px 23px;
  border-radius: 7px;
  text-align: left;
  box-shadow: 0px 10px 10px -12px #5E5E5E;
}

.infoSpecific {
  display: flex;
  margin-bottom: 37px;
}

.infoSpecific:nth-child(3) {
  margin-bottom: 0;
}

.infoTitle {
  font-size: 15px;
  color: #333333;
  margin-right: 35px;
}

.serviceWrap {
  margin-left: 31px;
  font-size: 11px;
  margin-bottom: 82px;
}

.serviceContent {
  color: #FA802C;
}

.lookLines {
  width: 100%
}

.lookLinesBtn {
  width: 300px;
  height: 43px;
  line-height: 43px;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  font-size: 16px;
  border-radius: 22px;
  background: -webkit-linear-gradient(left, #F7C377, #CA923B);
}

.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 99;

  .picture-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .picture {
      display: block;
      width: 280px;
      // height: 360px;
      background: rebeccapurple;
      margin: 0 auto;
    }

    .btn {
      width: 290px;
      height: 44px;
      border-radius: 22px;
      background: #ed2e2e;
      text-align: center;
      line-height: 44px;
      font-size: 16px;
      color: #fff;
      margin-top: 15px;
    }
  }

  .sku {
    width: 100%;
    min-height: 500px;
    border-radius: 8px 8px 0 0;
    background: #fff;
    position: absolute;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    padding: 14px;

    .tp {
      display: flex;
      width: 100%;
      position: relative;
      margin-bottom: 20px;

      .goods-cover {
        width: 78px;
        height: 78px;
        border-radius: 4px;
        background: #e0e0e0;
      }

      .goods-price {
        width: 250px;
        height: 78px;
        display: flex;
        // align-items: center;
        flex-direction: column;
        justify-content: space-around;
        box-sizing: border-box;
        padding-left: 12px;

        .pris {
          text-align: left;

          .hjy {
            color: #ed2e2e;
            font-size: 13px;
          }

          .hjy-count {
            color: #ed2e2e;
            font-size: 18px;
            font-weight: bold;
            margin-right: 12px;
          }

          .spe-icon {
            font-size: 14px;
            color: #ed2e2e;
            font-weight: bold;
            margin-left: 4px;
          }

          .gwj {
            color: #141f33;
            font-size: 13px;
          }
        }

        .guige {
          text-align: left;
          font-size: 13px;
          color: #99a0ad;
        }

        .close {
          width: 16px;
          height: 16px;
          background: url("../../../assets/images/closesku.png") no-repeat;
          background-size: 100% 100%;
          position: absolute;
          right: 4px;
          top: 4px;
        }
      }
    }

    .out {
      height: 200px;
      overflow: hidden;
      margin-bottom: 50px;

      .inner {
        height: 200px;
        overflow: auto;
      }
    }

    .status-box {
      width: 100%;
      margin-bottom: 8px;

      .tits {
        font-size: 16px;
        color: #141f33;
        margin-bottom: 12px;
        text-align: left;
      }

      .status {
        width: 100%;
        overflow: hidden;

        .box {
          width: auto;
          padding: 6px 20px;
          background: #f0f2f5;
          border-radius: 4px;
          font-size: 14px;
          color: #141414;
          display: inline-block;
          margin-right: 12px;
          margin-bottom: 12px;
          float: left;
        }

        .act {
          background: #fae9e8;
          color: #ed2e2e;
        }
      }
    }
  }

  .num-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #141f33;
    margin-bottom: 23px;

    .handles {
      .edge,
      .add {
        display: inline-block;
        width: 28px;
        height: 28px;
        background: #f0f2f5;
        border-radius: 4px;
        color: #616b80;
        font-size: 12px;
        line-height: 28px;
      }

      .cou {
        display: inline-block;
        width: 45px;
        height: 28px;
        background: #f0f2f5;
        border-radius: 4px;
        color: #616b80;
        font-size: 12px;
        line-height: 28px;
        margin: 0 4px;
      }
    }
  }

  .code-txt {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #99a0ad;
    margin-bottom: 4px;
  }

  .btn-box {
    width: 100%;
    height: 44px;
    border-radius: 22px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    .btn1 {
      width: 50%;
      height: 100%;
      line-height: 44px;
      background: #201e1d;
      color: #fff;
      font-size: 16px;
    }

    .btn2 {
      background: #ff001c;
    }

    .btn3 {
      width: 100%;
      height: 100%;
      line-height: 44px;
      color: #fff;
      font-size: 16px;
      background: #ff001c;
    }
  }
}
</style>
